import React, { useState, useContext, useEffect } from 'react';
import { Container, Box, Button, Typography } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { makeStyles } from 'tss-react/mui';

import EmojiEventsRoundedIcon from '@mui/icons-material/EmojiEventsRounded';
import MenuBookRoundedIcon from '@mui/icons-material/MenuBookRounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import ArticleRoundedIcon from '@mui/icons-material/ArticleRounded';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Link from 'next/link';
import Image from 'next/image';

// utils
import useWindowSize from 'utils/useWindowSize';

// store
import { GlobalContext } from 'context';

// components
import LoginCompose from 'components/local/compose/LoginCompose';
import RegisterCompose from 'components/local/compose/SignupCompose';

// actions
import { logout } from 'context/actions/auth';

const useStyles = makeStyles()(() => {
    return {
        root: {
            height: 80,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexDirection: 'row',
        },
        menus: {
            display: 'flex',
            textAlign: 'start',
            marginLeft: -14,
            color: 'red',
            backgroundColor: '#444444',
        },
        menuButton: {
            fontSize: 13,
            cursor: 'pointer',
            fontWeight: '700',
            whiteSpace: 'nowrap',
            color: '#899AB9',
        },
        selected: {
            fontSize: 13,
            color: 'white',
            fontWeight: 600,
            backgroundColor: '#185CCC',
        },
        logo: {
            width: 182,
            backgroundImage: 'url(/admin/svg/logo.svg)',
            backgroundRepeat: 'no-repeat',
            marginTop: -3,
            height: 33,
        },
        ButtonLigth: {
            color: '#175ccc',
            fontWeight: 600,
            fontSize: 13,
        },
        SignButton: {
            width: 'fit-content',
            padding: '5px 18px',
            fontWeight: 600,
            marginLeft: 5,
            opacity: 0.5,
            fontSize: 13,
            color: '#545454',
        },
        headRow: {
            width: 'fit-content',
        },
        headMenu: {
            color: '#545454',
            fontWeight: 600,
            margin: 4,
            fontSize: 13,
        },
        menuButton: {
            color: 'black',
            marginLeft: 'auto',
            width: 30,
            height: 30,
            cursor: 'pointer',
        },
        closeMenuButton: {
            color: '#000',
            marginTop: 20,
            marginBottom: 6,
            marginLeft: 'auto',
            width: 40,
            height: 40,
            cursor: 'pointer',
        },
        drawerText: {
            color: '#000',
            marginTop: 10,
            marginBottom: 10,
        },
        drawerPaper: {
            paddingLeft: 24,
            paddingRight: 24,
            overflowX: 'hidden',
        },
        drawerInnerBox: {
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
        },
        smallMenuBox: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        profile: {
            display: 'flex',
            border: '2px solid #175ccc',
            padding: 5,
            borderRadius: 27,
            alignItems: 'center',
        },
        crArrow: {
            backgroundColor: '#cfcfcf',
            width: 30,
            height: 30,
            borderRadius: 32,
            alignItems: 'center',
            display: 'flex',
            alignContent: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            marginRight: 5,
            '&:hover': {
                backgroundColor: '#c7c7c7',
            },
        },
        crHover: {
            margin: '0px 10px',
            borderRadius: 4,
        },
    };
});

const Header = () => {
    // const classes = useStyles();
    const { classes } = useStyles();
    const router = useRouter();
    const { sizes } = useWindowSize();
    const { state, dispatch } = useContext(GlobalContext);
    const [login, setLogin] = useState(false);
    const [register, setRegister] = useState(false);
    const [auth, setAuth] = useState({
        loggedIn: false,
    });
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    useEffect(() => {
        if (state?.auth) setAuth(state.auth);
    }, [state]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const closeLogin = () => {
        setLogin((prev) => !prev);
    };

    const closeRegister = () => {
        setRegister((prev) => !prev);
    };

    const loginClick = () => {
        setLogin(true);
        handleClose();
    };

    const RegisterClick = () => {
        setRegister(true);
        handleClose();
    };

    const handleMenuClick = (route) => {
        router.push(route);
        handleClose();
        // if (process.browser) window.scrollToBottom({ behavior: 'smooth' });
    };

    const handleVisitButton = (mod) => {
        if (auth.user.info.type === 'STUDENT') {
            router.push(`/student/${mod}`);
        } else if (auth.user.info.type === 'ADMIN') router.push('/admin/olympiads');
    };

    const handleLogout = async () => {
        await logout(dispatch);
    };

    const handleLoginRegister = async () => {
        setLogin(false);
        setRegister(true);
    };

    return (
        <>
            <Container maxWidth="lg" className={classes.root}>
                {!sizes.sm ? (
                    <>
                        <Link href="/" passHref>
                            <Box className={classes.logo}></Box>
                        </Link>
                        <Box className={classes.headRow}>
                            <Link href="/" passHref>
                                <Button className={classes.headMenu}>Нүүр</Button>
                            </Link>
                            <Link href="/news" passHref>
                                <Button className={classes.headMenu}>Олимпиадын мэдээ</Button>
                            </Link>
                            <Link href="/books" passHref>
                                <Button className={classes.headMenu}>Цахим ном</Button>
                            </Link>
                        </Box>
                        {auth.loggedIn ? (
                            <Box className={classes.profile}>
                                {auth?.user?.info?.avatar && (
                                    <Box
                                        style={{
                                            overflow: 'hidden',
                                            borderRadius: 25,
                                            height: 40,
                                        }}
                                    >
                                        <Image
                                            alt="avatar"
                                            src={auth.user.info.avatar}
                                            width={40}
                                            height={40}
                                        ></Image>
                                    </Box>
                                )}
                                <Box style={{ marginLeft: 10, marginRight: 20 }}>
                                    <Typography style={{ color: 'gray' }} variant="h6">
                                        {auth?.user?.info?.lastName}
                                    </Typography>
                                    <Typography
                                        style={{ fontSize: 14, marginTop: -7 }}
                                        variant="h6"
                                    >
                                        {auth?.user?.info?.firstName}
                                    </Typography>
                                </Box>
                                <Box className={classes.crArrow}>
                                    <KeyboardArrowDownIcon onClick={handleClick} />
                                </Box>
                                <Menu
                                    id="basic-menu"
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    MenuListProps={{
                                        'aria-labelledby': 'basic-button',
                                    }}
                                >
                                    <MenuItem
                                        onClick={() => handleVisitButton('olympiads')}
                                        className={classes.crHover}
                                    >
                                        <EmojiEventsRoundedIcon
                                            style={{
                                                fontSize: 19,
                                                marginRight: 10,
                                                color: '#afafaf',
                                                marginLeft: -5,
                                            }}
                                        />
                                        <Typography
                                            variant="h6"
                                            style={{ color: '#545454', marginRight: 15 }}
                                        >
                                            Олимпиад
                                        </Typography>
                                    </MenuItem>
                                    <MenuItem
                                        onClick={() => handleVisitButton('books')}
                                        className={classes.crHover}
                                    >
                                        <MenuBookRoundedIcon
                                            style={{
                                                fontSize: 18,
                                                marginRight: 10,
                                                marginLeft: -5,
                                                color: '#afafaf',
                                            }}
                                        />
                                        <Typography variant="h6" style={{ color: '#545454' }}>
                                            Номнууд
                                        </Typography>
                                    </MenuItem>
                                    <MenuItem
                                        onClick={() => handleVisitButton('profile')}
                                        className={classes.crHover}
                                    >
                                        <PersonRoundedIcon
                                            style={{
                                                fontSize: 18,
                                                marginRight: 10,
                                                color: '#afafaf',
                                                marginLeft: -5,
                                            }}
                                        />
                                        <Typography variant="h6" style={{ color: '#545454' }}>
                                            Профайл
                                        </Typography>
                                    </MenuItem>
                                    <MenuItem
                                        onClick={() => handleVisitButton('payments')}
                                        className={classes.crHover}
                                    >
                                        <ArticleRoundedIcon
                                            style={{
                                                fontSize: 18,
                                                marginRight: 10,
                                                marginLeft: -5,
                                                color: '#afafaf',
                                            }}
                                        />
                                        <Typography variant="h6" style={{ color: '#545454' }}>
                                            Багц
                                        </Typography>
                                    </MenuItem>
                                    <MenuItem onClick={handleLogout} className={classes.crHover}>
                                        <ExitToAppIcon
                                            style={{
                                                fontSize: 18,
                                                marginRight: 10,
                                                color: '#afafaf',
                                                marginLeft: -5,
                                            }}
                                        />
                                        <Typography variant="h6" style={{ color: '#545454' }}>
                                            Гарах
                                        </Typography>
                                    </MenuItem>
                                </Menu>
                            </Box>
                        ) : (
                            <Box>
                                <Button className={classes.SignButton} onClick={RegisterClick}>
                                    Бүртгүүлэх
                                </Button>
                                <Button className={classes.ButtonLigth} onClick={loginClick}>
                                    Нэвтрэх
                                </Button>
                            </Box>
                        )}
                    </>
                ) : (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '100%',
                        }}
                    >
                        <Link href="/" passHref>
                            <Box className={classes.logo}></Box>
                        </Link>
                        {auth.loggedIn ? (
                            <Box className={classes.profile}>
                                {auth?.user?.info?.avatar && (
                                    <Box
                                        style={{
                                            overflow: 'hidden',
                                            borderRadius: 25,
                                            height: 40,
                                            marginRight: 8,
                                        }}
                                    >
                                        <Image
                                            alt="avatar"
                                            src={auth.user.info.avatar}
                                            width={40}
                                            height={40}
                                        ></Image>
                                    </Box>
                                )}
                                <Box className={classes.crArrow}>
                                    <KeyboardArrowDownIcon onClick={handleClick} />
                                </Box>
                                <Menu
                                    id="basic-menu"
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    MenuListProps={{
                                        'aria-labelledby': 'basic-button',
                                    }}
                                >
                                    <MenuItem
                                        onClick={() => handleVisitButton('olympiads')}
                                        className={classes.crHover}
                                    >
                                        <EmojiEventsRoundedIcon
                                            style={{
                                                fontSize: 19,
                                                marginRight: 10,
                                                color: '#afafaf',
                                                marginLeft: -5,
                                            }}
                                        />
                                        <Typography
                                            variant="h6"
                                            style={{ color: '#545454', marginRight: 15 }}
                                        >
                                            Олимпиад
                                        </Typography>
                                    </MenuItem>
                                    <MenuItem
                                        onClick={() => handleVisitButton('books')}
                                        className={classes.crHover}
                                    >
                                        <MenuBookRoundedIcon
                                            style={{
                                                fontSize: 18,
                                                marginRight: 10,
                                                marginLeft: -5,
                                                color: '#afafaf',
                                            }}
                                        />
                                        <Typography variant="h6" style={{ color: '#545454' }}>
                                            Номнууд
                                        </Typography>
                                    </MenuItem>
                                    <MenuItem
                                        onClick={() => handleVisitButton('profile')}
                                        className={classes.crHover}
                                    >
                                        <PersonRoundedIcon
                                            style={{
                                                fontSize: 18,
                                                marginRight: 10,
                                                color: '#afafaf',
                                                marginLeft: -5,
                                            }}
                                        />
                                        <Typography variant="h6" style={{ color: '#545454' }}>
                                            Профайл
                                        </Typography>
                                    </MenuItem>
                                    <MenuItem
                                        onClick={() => handleVisitButton('payments')}
                                        className={classes.crHover}
                                    >
                                        <ArticleRoundedIcon
                                            style={{
                                                fontSize: 18,
                                                marginRight: 10,
                                                marginLeft: -5,
                                                color: '#afafaf',
                                            }}
                                        />
                                        <Typography variant="h6" style={{ color: '#545454' }}>
                                            Багц
                                        </Typography>
                                    </MenuItem>
                                    <MenuItem onClick={handleLogout} className={classes.crHover}>
                                        <ExitToAppIcon
                                            style={{
                                                fontSize: 18,
                                                marginRight: 10,
                                                color: '#afafaf',
                                                marginLeft: -5,
                                            }}
                                        />
                                        <Typography variant="h6" style={{ color: '#545454' }}>
                                            Гарах
                                        </Typography>
                                    </MenuItem>
                                </Menu>
                            </Box>
                        ) : (
                            <Box className={classes.profile}>
                                <Box className={classes.crArrow}>
                                    <KeyboardArrowDownIcon onClick={handleClick} />
                                </Box>
                                <Menu
                                    id="basic-menu"
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    MenuListProps={{
                                        'aria-labelledby': 'basic-button',
                                    }}
                                >
                                    <MenuItem
                                        onClick={() => router.push('/')}
                                        className={classes.crHover}
                                    >
                                        <HomeRoundedIcon
                                            style={{
                                                fontSize: 18,
                                                marginRight: 10,
                                                marginLeft: -5,
                                                color: '#afafaf',
                                            }}
                                        />
                                        <Typography variant="h6" style={{ color: '#545454' }}>
                                            Нүүр хуудас
                                        </Typography>
                                    </MenuItem>
                                    <MenuItem
                                        onClick={() => handleMenuClick('/news')}
                                        className={classes.crHover}
                                    >
                                        <EmojiEventsRoundedIcon
                                            style={{
                                                fontSize: 19,
                                                marginRight: 10,
                                                color: '#afafaf',
                                                marginLeft: -5,
                                            }}
                                        />
                                        <Typography
                                            variant="h6"
                                            style={{ color: '#545454', marginRight: 15 }}
                                        >
                                            Олимпиадын мэдээ
                                        </Typography>
                                    </MenuItem>
                                    <MenuItem
                                        onClick={() => handleMenuClick('/books')}
                                        className={classes.crHover}
                                    >
                                        <MenuBookRoundedIcon
                                            style={{
                                                fontSize: 18,
                                                marginRight: 10,
                                                marginLeft: -5,
                                                color: '#afafaf',
                                            }}
                                        />
                                        <Typography variant="h6" style={{ color: '#545454' }}>
                                            Цахим ном
                                        </Typography>
                                    </MenuItem>
                                    <MenuItem onClick={RegisterClick} className={classes.crHover}>
                                        <ArticleRoundedIcon
                                            style={{
                                                fontSize: 18,
                                                marginRight: 10,
                                                marginLeft: -5,
                                                color: '#afafaf',
                                            }}
                                        />
                                        <Typography variant="h6" style={{ color: '#545454' }}>
                                            Бүртгүүлэх
                                        </Typography>
                                    </MenuItem>
                                    <MenuItem onClick={loginClick} className={classes.crHover}>
                                        <ExitToAppIcon
                                            style={{
                                                fontSize: 18,
                                                marginRight: 10,
                                                color: '#afafaf',
                                                marginLeft: -5,
                                            }}
                                        />
                                        <Typography variant="h6" style={{ color: '#545454' }}>
                                            Нэвтрэх
                                        </Typography>
                                    </MenuItem>
                                </Menu>
                            </Box>
                        )}
                    </Box>
                )}
            </Container>
            {login && (
                <LoginCompose
                    handleClose={closeLogin}
                    handleRegister={handleLoginRegister}
                    open={login}
                />
            )}
            {register && <RegisterCompose handleClose={closeRegister} open={register} />}
        </>
    );
};

Header.propTypes = {
    className: PropTypes.string,
    menus: PropTypes.array,
};

Header.defaultProps = {
    menus: [],
};

export default Header;
