import React from 'react';
import { Box } from '@mui/material';

// components
import Header from 'components/local/layout/Header';
import Footer from 'components/local/layout/Footer';

export default function HomeLayout({ children }) {
    return (
        <Box style={{ width: '100%', background: '#f6f6f6' }}>
            <Header />
            {children}
            <Footer />
        </Box>
    );
}
