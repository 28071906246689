import { useState, useEffect, useContext } from 'react';
import { useRouter } from 'next/router';
import { Box, Typography, Dialog } from '@mui/material';
import { makeStyles } from '@mui/styles';
import FacebookLogin from 'react-facebook-login';

// components
import Button from 'components/global/form/Button';
import Input from 'components/global/form/Input';
import Alert from 'components/global/helpers/Alert';

// context
import { GlobalContext } from 'context';

// actions
import { createAccount, confirmEmailCode, createPassword } from 'context/actions/accounts';
import { facebookLogin } from 'context/actions/auth';

const useStyles = makeStyles(() => ({
    container: {
        width: 380,
        justifyContent: 'center',
        alignItems: 'center',
        paddingBottom: 30,
        paddingTop: 30,
    },
    inputBox: {
        backgroundColor: '#FFF',
        padding: '30px 30px 45px 30px',
        borderRadius: 8,
        display: 'flex',
        flexDirection: 'column',
    },
    title: {
        marginBottom: 10,
    },
    alert: {
        marginTop: 16,
    },
    customTextField: {
        marginTop: 10,
    },
    buttons: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        marginTop: 20,
    },
    otherAuth: {
        marginTop: 30,
        marginBottom: 20,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    line: {
        height: 1,
        width: '42%',
        background: 'gray',
    },
    otherTitle: {
        fontSize: 12,
        cursor: 'pointer',
        padding: 7,
        fontWeight: 600,
        color: '#949494',
    },
}));

const SignUp = ({ open, handleClose }) => {
    const classes = useStyles();
    const router = useRouter();
    const { dispatch } = useContext(GlobalContext);
    const [action, setAction] = useState('email_check');
    const [email, setEmail] = useState('');
    const [code, setCode] = useState('');
    const [token, setToken] = useState('');
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    const [alert, setAlert] = useState({ show: false, message: '' });

    const clearAlert = () => {
        setAlert({
            show: false,
            message: null,
        });
    };

    const responseFacebook = async (response) => {
        const datas = {
            name: response.name || null,
            facebookId: response.id,
            avatar: response.picture.data.url,
        };
        if (response.email) {
            Object.assign(datas, { email: response.email });
        }
        const result = await facebookLogin(dispatch, datas);

        if (result.errors && result.code === 404) {
            setAlert({
                show: true,
                message: 'Нэвтрэлт амжилтгүй боллоо!',
            });
        } else {
            clearAlert();
            if (result.data.info.type === 'STUDENT') router.push('/student/profile');
            else if (result.data.info.type === 'ADMIN') router.push('/admin/olympiads');
        }
    };

    const handleEmailSend = async () => {
        if (!email) {
            setAlert({
                show: true,
                message: 'Утас эсвэл Имэйл хаягaa оруулна уу!',
            });
            return;
        }

        setAlert({ show: false, message: '' });

        const result = await createAccount({ email });
        if (result.message === 'duplicate_user' && result.code === 404) {
            setAlert({
                show: true,
                message: 'Утас эсвэл Имэйл хаяг бүртгэлтэй байна!',
            });
        } else {
            setToken(result.data.signupToken);
            setAction('confirm_code');
            clearAlert();
        }
    };

    const handleCodeSend = async () => {
        if (!code) {
            setAlert({
                show: true,
                message: 'Баталгаажуулах кодоо оруулна уу!',
            });
            return;
        }

        clearAlert();

        const result = await confirmEmailCode({ code: code, signupToken: token });
        if (result.message === 'confirm_code_notmatch' && result.code === 404) {
            setAlert({
                show: true,
                message: 'Баталгаажуулах код буруу байна!',
            });
        } else {
            setAction('create_password');
            clearAlert();
        }
    };

    const handleSignUp = async () => {
        if (!password && password2) {
            setAlert({
                show: true,
                message: 'Нууц үгээ оруулна уу!',
            });
            return;
        }

        if (password !== password2) {
            setAlert({
                show: true,
                message: 'Нууц үгийн баталгаа буруу байна!',
            });
            return;
        }

        clearAlert();

        const result = await createPassword(dispatch, {
            password: password,
            confirmPassword: password2,
            lastName: null,
            firstName: null,
            grade: 1,
            school: null,
            signupToken: token,
        });

        if (result.message === 'confirm_code_notmatch' && result.code === 404) {
            setAlert({
                show: true,
                message: 'Баталгаажуулах код буруу байна!',
            });
        } else if (result.code === 422) {
            setAlert({
                show: true,
                message: 'Бүртгүүлэхэд алдаа гарлаа!',
            });
        } else {
            clearAlert();
            if (result.data.info.type === 'STUDENT') router.push('/student/profile');
            else if (result.data.info.type === 'ADMIN') router.push('/admin/olympiads');
        }
    };

    const handleCodeChange = (e) => setCode(e.target.value);
    const handleEmailChange = (e) => setEmail(e.target.value);
    const handlePasswordChange = (e) => setPassword(e.target.value);
    const handlePassword2Change = (e) => setPassword2(e.target.value);

    if (action === 'email_check') {
        return (
            <Dialog
                classes={{ paper: classes.container }}
                maxWidth={'lg'}
                onClose={handleClose}
                open={open}
            >
                <Box className={classes.inputBox}>
                    <Typography variant={'h3'} className={classes.title}>
                        Бүртгүүлэх цонх
                    </Typography>
                    <Input
                        title="Утас эсвэл Имэйл хаяг"
                        onChange={handleEmailChange}
                        value={email}
                    />
                    {alert.show && <Alert message={alert.message} />}
                    <Box className={classes.buttons}>
                        <Button title="Илгээх" width={282} onClick={handleEmailSend} />
                    </Box>
                    <Box className={classes.otherAuth}>
                        <Box className={classes.line}></Box>
                        <Typography className={classes.otherTitle} style={{ textAlign: 'center' }}>
                            Эсвэл
                        </Typography>
                        <Box className={classes.line}></Box>
                    </Box>
                    <FacebookLogin
                        size="small"
                        appId="302546468711290"
                        autoLoad={false}
                        fields="name,email,picture"
                        textButton="Фэйсбүүкээр бүртгүүлэх"
                        callback={responseFacebook}
                        cssClass="my-facebook-button-class"
                        redirectUri="https://olympiad.mn"
                        disableMobileRedirect={true}
                        isMobile={false}
                    />
                </Box>
            </Dialog>
        );
    } else if (action === 'confirm_code') {
        return (
            <Dialog
                classes={{ paper: classes.container }}
                maxWidth={'lg'}
                onClose={handleClose}
                open={open}
            >
                <Box className={classes.inputBox}>
                    <Typography variant={'h3'} className={classes.title}>
                        Баталгаажуулах цонх
                    </Typography>
                    <Input
                        title="Код оруулах"
                        type="number"
                        onChange={handleCodeChange}
                        value={code}
                    />
                    {alert.show && <Alert message={alert.message} />}
                    <Box className={classes.buttons}>
                        <Button title="Илгээх" width={282} onClick={handleCodeSend} />
                    </Box>
                    <Box style={{ marginTop: 8 }}>
                        <Typography variant="body2" style={{ color: '#949494' }}>
                             Бүртгүүлсэн утас эсвэл имэйл хаягт ирсэн &nbsp;баталгаажуулах кодыг
                            оруулна уу!
                        </Typography>
                    </Box>
                </Box>
            </Dialog>
        );
    } else if (action === 'create_password') {
        return (
            <Dialog
                classes={{ paper: classes.container }}
                maxWidth={'lg'}
                onClose={handleClose}
                open={open}
            >
                <Box className={classes.inputBox}>
                    <Typography variant={'h3'} className={classes.title}>
                        Нууц үг оруулах
                    </Typography>
                    <Input
                        title="Нууц үг"
                        type="password"
                        onChange={handlePasswordChange}
                        value={password}
                    />
                    <Input
                        title="Нууц үг давтах"
                        type="password"
                        onChange={handlePassword2Change}
                        value={password2}
                    />
                    {alert.show && <Alert message={alert.message} />}
                    <Box className={classes.buttons}>
                        <Button title="Бүртгүүлэх" width={'100%'} onClick={handleSignUp} />
                    </Box>
                </Box>
            </Dialog>
        );
    }
};

export default SignUp;
